/* variables by Robi */
:root {
  --primary-color: #273247;
  --secondary-color: #1f8efa;
  --tercary-color: #ffffff;
  --quadrary-color: #4f4f4f;
  --pentary-color: #ffcc33;
  --hexary-color: #c4c4c4;
  --septimary-color: #fa461f;
  --octamary-color: #cc0066;

  --primary-font-family: "Montserrat";
  --secondary-font-family: "Roboto";

  /* OVB LEVEL COLORS */
  --lvl_6_color: #cc0066;
  --lvl_5_color: #ffcc33;
  --lvl_4_color: #006666;
  --lvl_3_color: #8bbf18;
  --lvl_2_color: #9470b7;
  --lvl_1_color: #00b7e5;

  /*     zoom: 75%; */
}

/*  
.preventZoom > * {
    zoom: 2;    

}  
*/

body {
  display: block;
  font-family: var(--primary-font-family);
  letter-spacing: 2px;
  color: var(--primary-color);
  margin: 0px;
  overflow-x: hidden;
}

/* ::-webkit-scrollbar {
    display: none;
} */

::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

/* THUMB */
::-webkit-scrollbar-thumb {
  background: #adadad;
  border-radius: 13px;
}

/* THUMB HOVER */
::-webkit-scrollbar-thumb:hover {
  background: rgb(31, 142, 250);
}

/* CORNER */
::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*
body:not([class^='preventZoom']>*) {

    width: 133.5%;
    transform: scale(0.75);
    transform-origin: 0 0

}
*/

/* unvisited link */
a:link {
  color: var(--secondary-color);
}
/* visited link */
a:visited {
  color: var(--secondary-color);
}
/* mouse over link */
a:hover {
  color: var(--secondary-color);
}
/* selected link */
a:active {
  color: var(--secondary-color);
}

/*
=====================
COLOR SECTION
======================
*/

.primaryColor {
  color: var(--primary-color);
}

.secondaryColor {
  color: var(--secondary-color);
}

.tercaryColor {
  color: var(--tercary-color);
}

.quadraryColor {
  color: var(--quadrary-color);
}

.septimaryColor {
  color: var(--septimary-color);
}
.octamaryColor {
  color: var(--octamary-color);
}

/*
=====================
TYPO SECTION
======================
*/

/*
H1
*/

/* DEFAULT */
h1 {
  color: var(--primary-color);
  font-size: 130px;
  margin-block-end: 0px;
  margin-block-start: 0px;
  text-transform: uppercase;
}

/*
H2
*/

/* DEFAULT */
h2 {
  color: var(--primary-color);
  font-family: var(--secondary-font-family);
  font-size: 50px;
  letter-spacing: 0.2em;
  font-style: normal;
  font-weight: 300;
  margin-block-end: 0px;
  margin-block-start: 0px;
  text-transform: uppercase;
  margin-bottom: 30px;
}
h2.conversation {
  font-size: 32px;
  font-weight: lighter;
}

h4 {
  font-size: 30px;
  margin-block-end: 0px;
  margin-block-start: 0px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

h5 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-block-end: 0px;
  margin-block-start: 0px;
}

h6 {
  font-size: 12px;
  margin-block-end: 0px;
  margin-block-start: 0px;
  letter-spacing: 0.25em;
  margin-block-end: 0px;
  margin-block-start: 0px;
}

p {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

p.conversation.question {
  font-size: 25px;
  color: white;
  margin-bottom: 10px;
  font-weight: lighter;
  margin-top: 10px;
  margin-left: 5px;
}

p.conversation.subTitle {
  font-size: 14px;
  color: white;
}

p.conversation.answers {
  color: rgb(31, 142, 250);
  font-size: 15px;
  font-weight: normal;
}

p.body_1 {
  font-family: var(--secondary-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
}

p.percent {
  font-family: var(--secondary-font-family);
  color: var(--primary-color);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.2em;
}

p.dropmenu {
  line-height: 11px;
  font-size: 11px;
  letter-spacing: 0.15em;
}
p.statiscticstable {
  line-height: 11px;
  font-size: 14px;
  letter-spacing: 0.15em;
  line-height: 15px;
  text-align: start;
}

/*
=====================
P SECTION
======================
*/

p.menu.navbutton {
  font-size: 6px;
  font-weight: bold;
}

/*
=====================
BUTTON SECTION
======================
*/

button:not([class^="ql-"]):not([class^="react-inputs-validation"]):not([class^="react-datepicker__navigation"]):not([class^="react-datepicker__navigation--next"]):not([class^="askButton"]) {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70px;
  min-width: 386px;
  padding: 20px;
  max-width: 386px;
  border-radius: 35px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.25em;
  color: var(--tercary-color);
  border: 0px solid transparent;
}

button.primary {
  background-color: var(--primary-color);
}

button.secondary {
  background-color: var(--secondary-color);
}

button.tercary {
  background-color: var(--tercary-color);
  color: var(--primary-color);
}

button.septimary {
  background-color: var(--septimary-color);
  color: var(--tercary-color);
}

button.octamary {
  background-color: var(--octamary-color);
}

.backButton {
  font-size: 12px !important;
}

.miniButton {
  font-weight: bold;
  font-size: 10px;
  font-family: var(--primary-font-family);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 145px;
  /*     max-width: 145px!important;
   min-width: 145px!important; */
  height: 40px;
  border-radius: 15px;
}
.miniButton.input {
  min-height: 35px;
  min-width: 250px;
}

.miniButton.primary {
  border: 2.5px var(--primary-color) solid;
  background-color: var(--primary-color);
  color: var(--tercary-color);
  cursor: pointer;
}

.miniButton.secondary {
  border: 2.5px var(--secondary-color) solid;
  background-color: var(--secondary-color);
  color: var(--tercary-color);
  margin-top: 30px;
  cursor: pointer;
}

.miniButton.hexary {
  border: 2.5px var(--hexary-color) solid;
  background-color: var(--hexary-color);
  cursor: pointer;
  margin-bottom: 50px;
  color: var(--tercary-color);
}

.miniButton.outline {
  background-color: transparent;
}

.buttonStatistics {
  border-radius: 35px !important;
  font-size: 12px !important;
  font-weight: normal !important;
  max-width: 600px !important;
  min-width: 300px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-bottom: 10px !important;
}

*,
*:focus,
*:hover {
  outline: none;
}

.react-datepicker__triangle {
  display: none;
}
.react-datepicker__header {
  background-color: var(--secondary-color) !important;
}
.react-datepicker__current-month {
  color: white !important;
}
.react-datepicker__day-name {
  color: white !important;
}
.react-datepicker__day--selected {
  background-color: var(--primary-color) !important;
}
.react-datepicker {
  border-radius: 15px;
}
.react-datepicker__day {
  width: 25px !important;
}

input {
  font-size: 15px;
  font-weight: 600;
}

input:disabled {
  background-color: #ccc !important;
}

.dateInput {
  background: #cfd7db !important;

  cursor: pointer;
  border-radius: 20px !important;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-family: "Roboto";
}

.react-datepicker {
  width: 320px !important;
}
.react-datepicker__month-wrapper {
  display: flex !important;
  justify-content: space-evenly;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  margin-top: 20px;
  margin-bottom: 20px;
}

.react-datepicker__header {
  height: auto;
  min-height: 40px;
}
.react-datepicker__month-read-view--down-arrow {
  border-top-color: white !important;
}
.react-datepicker__year-read-view--down-arrow {
  border-top-color: white !important;
}
.react-datepicker__navigation--previous {
  border-right-color: white !important;
}
.react-datepicker__navigation--next {
  border-left-color: white !important;
}
.react-datepicker__month-read-view--selected-month {
  color: white;
}
.react-datepicker__year-read-view--selected-year {
  color: white;
}
.react-datepicker__year-dropdown {
  background-color: white !important;
}
.react-datepicker__month-dropdown {
  background-color: white !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: white !important;
  background-color: rgb(39, 50, 71) !important;
}

.react-datepicker__month-container {
  width: 320px;
}

.nice-dates {
  z-index: 1;
}

.nice-dates input {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 4px;
  font-family: "Roboto";
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 10;
}

.datePickerInput {
  background: #cfd7db !important;
  border-radius: 100px !important;
  border: 1px solid #cfd7db !important;
  width: 95px !important;
}

/*
=====================
CONTAINER SECTION
======================
*/

.container {
  background: #f3f5f7;
  box-shadow: 0px 16px 30px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  width: 100%;
  padding-bottom: 82px;
}

iframe {
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

/* 
DIARY
*/

.nice-dates-popover {
  max-width: 450px !important;
}

.react-datepicker-popper {
  top: 100px !important;
  z-index: 500 !important;
}
/* 
IMAGE CONTAINER
*/
.eJEChV {
  justify-content: center !important;
  align-items: center !important;
}

.sc-AxmLO.khrfxO {
  min-width: 650px;
}
.ReactCrop.ReactCrop--fixed-aspect.ReactCrop--rule-of-thirds {
  min-width: 600px;
}
.ReactCrop__image {
  min-width: 600px;
}

/*
MEDIA SCREEN
*/

@media screen and (max-width: 992px) {
  /* typeo */
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 40px;
  }
  h2.conversation {
    font-size: 19px;
  }
  h4 {
    font-size: 20px;
  }

  .nice-dates-popover {
    max-width: 250px !important;
  }

  /* button */
  button:not([class^="ql-"]):not([class^="react-inputs-validation"]):not([class^="react-datepicker__navigation"]):not([class^="react-datepicker__navigation--next"]):not([class^="askButton"]) {
    min-height: 70px;
    min-width: 100px;
    max-width: 200px;
    border-radius: 35px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.25em;
    /*   color: #FFFFFF; */
    border: 0px solid transparent;
  }

  /* container */
  .container {
    margin: 0px;
    padding: 24px;
    width: 100%;
    border-radius: 0px;
  }

  p.conversation.question {
    font-size: 17px;
  }

  p.conversation.subTitle {
    font-size: 12px;
  }

  .sc-AxmLO.khrfxO {
    min-width: 450px;
  }
  .ReactCrop.ReactCrop--fixed-aspect.ReactCrop--rule-of-thirds {
    min-width: 400px;
  }
  .ReactCrop__image {
    min-width: 400px;
  }
}

@media screen and (max-width: 420px) {
  /* typeo */
  h1 {
    font-size: 25px;
  }
  h2 {
    font-size: 20px;
  }
  h2.conversation {
    font-size: 13px;
  }
  h4 {
    font-size: 15px;
  }
  p.conversation.question {
    font-size: 12px;
  }

  p.conversation.subTitle {
    font-size: 8px;
  }
  /* button */

  /* container */
  .sc-AxmLO.khrfxO {
    min-width: 350px;
  }
  .ReactCrop.ReactCrop--fixed-aspect.ReactCrop--rule-of-thirds {
    min-width: 300px;
  }
  .ReactCrop__image {
    min-width: 300px;
  }
}

@media screen and(max-width: 320px) {
  .sc-AxmLO.khrfxO {
    min-width: 320px;
  }
  .react-datepicker {
    margin-left: -40px !important;
  }
}

.react-inputs-validation__button {
  padding: 0;
}

input[type="text"].react-inputs-validation__textbox__input {
  border-radius: 8px;
}

.react-datepicker__input-container input {
  border-radius: 8px !important;
}
/* 
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="13px"]::before {
    content: 'Normal';
    font-size: 13px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="15px"]::before {
    content: 'Large';
    font-size: 15px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
    content: 'Huge';
    font-size: 18px !important;
} */
