input[type="number"],
input[type="password"],
input[type="phone"],
input[type="text"],
input[type="date"],
.react-inputs-validation__select__container___3jgUR,
select,
textarea {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    background-color: #fff;
    background-position: 100%;
    background-repeat: no-repeat;
    border: 1px solid #4F4F4F;
    box-sizing: border-box;
    border-radius: 8px;
    height:52px;
    width:100%;
    min-width: 75px;
    padding:15px;
    font-family: "Roboto";
}
select {
    background: transparent !important;
}
.react-inputs-validation__ellipsis___3-Api {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.react-inputs-validation__textbox__wrapper___3tnXv {
    height: auto;
}
input[type="number"].react-inputs-validation__textbox__input___20hDL,
input[type="password"].react-inputs-validation__textbox__input___20hDL,
input[type="phone"].react-inputs-validation__textbox__input___20hDL,
input[type="date"].react-inputs-validation__textbox__input___20hDL,
input[type="text"].react-inputs-validation__textbox__input___20hDL{
    display: block;
    margin: 0;
    width: 100%;
    font-size: 15px;
    font-weight:600;
    letter-spacing: 4px;
    font-family: "Roboto";
    padding: 15px;
    border: 0.5px solid #4F4F4F;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;

}

input[type="number"].react-inputs-validation__textbox__input___20hDL.react-inputs-validation__error___2aXSp,
input[type="password"].react-inputs-validation__textbox__input___20hDL.react-inputs-validation__error___2aXSp,
input[type="phone"].react-inputs-validation__textbox__input___20hDL.react-inputs-validation__error___2aXSp,
input[type="text"].react-inputs-validation__textbox__input___20hDL.react-inputs-validation__error___2aXSp {
    border: 1px solid var(--septimary-color);
    color: var(--septimary-color);
}
input[type="number"].react-inputs-validation__textbox__input___20hDL.react-inputs-validation__success___3TpwE,
input[type="password"].react-inputs-validation__textbox__input___20hDL.react-inputs-validation__success___3TpwE,
input[type="phone"].react-inputs-validation__textbox__input___20hDL.react-inputs-validation__success___3TpwE,
input[type="text"].react-inputs-validation__textbox__input___20hDL.react-inputs-validation__success___3TpwE {
    border: 1px solid #2fca6f;
    color: #2fca6f;
}
input[type="number"].react-inputs-validation__textbox__input___20hDL.react-inputs-validation__disabled___1-57g,
input[type="password"].react-inputs-validation__textbox__input___20hDL.react-inputs-validation__disabled___1-57g,
input[type="phone"].react-inputs-validation__textbox__input___20hDL.react-inputs-validation__disabled___1-57g,
input[type="text"].react-inputs-validation__textbox__input___20hDL.react-inputs-validation__disabled___1-57g {
    border: 1px solid #ccc;
    color: #000;
    background: #f8f8f8;
    cursor: pointer;
}
.react-inputs-validation__msg___pxv8o.react-inputs-validation__error___2aXSp {
    color: var(--septimary-color);
}
.react-inputs-validation__msg___pxv8o.react-inputs-validation__error___2aXSp,
.react-inputs-validation__msg___pxv8o.react-inputs-validation__success___3TpwE {
    margin-top: 2px;
    font-size: 12px;
    padding-left: 4px;
}
.react-inputs-validation__msg___pxv8o.react-inputs-validation__success___3TpwE {
    color: #2fca6f;
}
::-webkit-input-placeholder {
    color: #a5a5a5;
    font-family: "Roboto";
}
:-ms-input-placeholder {
    color: #a5a5a5;
    font-family: "Roboto";
}
:-moz-placeholder,
::-moz-placeholder {
    color: #a5a5a5;
    font-family: "Roboto";
    opacity: 1;
}
.react-inputs-validation__textarea__wrapper___35GhF {
    height: auto;
}
textarea.react-inputs-validation__textarea__input___1CFm_ {
    resize: none;
    display: block;
    margin: 0;
    width: 100%;
    font-size: 14px;
    padding: 2%;
    border: 1px solid #4F4F4F;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 4px;
    min-height: 160px;
    padding:15px;
    font-family: "Roboto";
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}
textarea.react-inputs-validation__textarea__input___1CFm_.react-inputs-validation__error___2aXSp {
    border: 1px solid var(--septimary-color);
    color: var(--septimary-color);
}
textarea.react-inputs-validation__textarea__input___1CFm_.react-inputs-validation__success___3TpwE {
    border: 1px solid #2fca6f;
    color: #2fca6f;
}
textarea.react-inputs-validation__textarea__input___1CFm_.react-inputs-validation__disabled___1-57g {
    border: 1px solid #ccc;
    color: #ccc;
    background: #f8f8f8;
    cursor: not-allowed;
}
input[type="radio"] {
    position: absolute;
    opacity: 0;
    display: none;
}
.react-inputs-validation__radiobox__container___FD4vb.react-inputs-validation__disabled___1-57g,
.react-inputs-validation__radiobox__input___2uX2-.react-inputs-validation__disabled___1-57g,
.react-inputs-validation__radiobox__label___2tQsB.react-inputs-validation__disabled___1-57g:before,
.react-inputs-validation__radiobox__wrapper___1zZ30.react-inputs-validation__disabled___1-57g {
    color: #ccc;
    cursor: not-allowed;
}
.react-inputs-validation__radiobox__label___2tQsB {
    display: inline-block\9;
    display: flex;
    align-items: center;
}
.react-inputs-validation__radiobox__label___2tQsB:before {
    content: "";
    border: 1px solid #e5e5e5;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    width: 14px;
    height: 14px;
    position: relative;
    margin-right: 1em;
    cursor: pointer;
    text-align: center;
    transition: all 0.25s ease;
}
.react-inputs-validation__radiobox__label___2tQsB.react-inputs-validation__disabled___1-57g:before {
    border: 1px solid #ccc;
    background-color: #f8f8f8;
}
.react-inputs-validation__radiobox__label___2tQsB.react-inputs-validation__checked___2O0Ju:before {
    background-color: #4a4a4a;
    box-shadow: inset 0 0 0 4px #f4f4f4;
}
.react-inputs-validation__button___9JBRL.react-inputs-validation__radiobox__item___ejuz1 {
    display: inline-block\9;
    width: auto;
}
.react-inputs-validation__radiobox__item___ejuz1.react-inputs-validation__success___3TpwE .react-inputs-validation__radiobox__label___2tQsB.react-inputs-validation__checked___2O0Ju:before {
    border-color: #2fca6f;
}
.react-inputs-validation__radiobox__item___ejuz1.react-inputs-validation__error___2aXSp .react-inputs-validation__radiobox__label___2tQsB:before {
    border-color: var(--septimary-color);
}
input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}
.react-inputs-validation__checkbox__input___1yF4X {
    display: none;
}
.react-inputs-validation__checkbox__container___3I1rX {
    display: flex;
    align-items: center;
}
.react-inputs-validation__checkbox__box___1uj8A {
    width: 15px;
    height: 15px;
    position: relative;
    border: 1px solid #e5e5e5;
    margin-right: 10px;
}
.react-inputs-validation__checkbox__box___1uj8A,
.react-inputs-validation__checkbox__container___3I1rX label > div {
    vertical-align: middle\9;
    display: inline-block\9;
}
.react-inputs-validation__checkbox__box___1uj8A.react-inputs-validation__disabled___1-57g {
    background: #f8f8f8;
}
.react-inputs-validation__checkbox__box___1uj8A.react-inputs-validation__error___2aXSp {
    border-color: var(--septimary-color);
}
.react-inputs-validation__checkbox__box___1uj8A.react-inputs-validation__success___3TpwE {
    border-color: #2fca6f;
}
.react-inputs-validation__checkbox__wrapper___1c1rD.react-inputs-validation__checked___2O0Ju .react-inputs-validation__checkbox__box___1uj8A .react-inputs-validation__box___3E9nu {
    opacity: 1;
}
.react-inputs-validation__checkbox__box___1uj8A .react-inputs-validation__box___3E9nu {
    content: "";
    width: 8px;
    height: 4px;
    position: absolute;
    top: 3px;
    left: 2px;
    border: 3px solid #4a4a4a;
    border-top: none;
    border-right: none;
    background: transparent;
    opacity: 0;
    transform: rotate(-45deg);
}
.react-inputs-validation__button___9JBRL {
    width: 100%;
    display: inline-block;
    text-align: left;
    cursor: pointer;
    background: none;
    border: none;
    overflow: visible;
    padding:0px;
    min-height: 30px;
}
.react-inputs-validation__select__wrapper___1B4OH {
    cursor: pointer;
    position: relative;
}
.react-inputs-validation__select__input___3h-P7 {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.react-inputs-validation__select__container___3jgUR {
 /*    padding: 2%;
    border: 1px solid #d3d3d3; */
}
.react-inputs-validation__select__container___3jgUR .react-inputs-validation__select__button___vEDoq {
    outline: none;
}
.react-inputs-validation__select__container___3jgUR.react-inputs-validation__error___2aXSp {
    border-color: var(--septimary-color);
}
.react-inputs-validation__select__container___3jgUR.react-inputs-validation__success___3TpwE {
    border-color: #2fca6f;
}
.react-inputs-validation__select__container___3jgUR.react-inputs-validation__disabled___1-57g {
    background: #f8f8f8;
}
.react-inputs-validation__select__options-item___30yY4 {
    background: #fff;
    transition: background 0.4s, color 0.2s;
    cursor: none;
    padding: 10px 0;
}
.react-inputs-validation__select__options-item___30yY4 span {
    padding: 0 10px;
}

.react-inputs-validation__select__options-item___30yY4:hover span {
    color:white

}

.react-inputs-validation__select__options-item-show-cursor___1ZT3b {
    cursor: pointer;
    display: flex;
}
.react-inputs-validation__select__searchInputWrapper___3R5P_ {
    padding: 10px;
    position: relative;
    display: flex;
}
.react-inputs-validation__select__searchInputSearchIcon___1Q8xX {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
}
.react-inputs-validation__select__searchInputRemoveIcon___1A1tR {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
}
.react-inputs-validation__select__searchInput___1lN24 {
    padding: 0 30px;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 14px;
    height: 40px;
    width: 100%;
    border: 1px solid #dfe2e7;
    color: #7f8fa4;
    display: block;
}
.react-inputs-validation__select__no-mouse___2uRg6 {
    cursor: none;
}
.react-inputs-validation__select__hover-active___2z1MQ {
    background: var(--primary-color);
    color: #fff;
}
.react-inputs-validation__select__options-item___30yY4.react-inputs-validation__active___3eZBB {
   /*  background: var(--secondary-bg); */
    color: black;
}
.react-inputs-validation__select__options-container-animate___2Rm-- {
    z-index: 1;
    margin-top: 2%;
    position: absolute;
    background: #fff;
    width: 100%;
    opacity: 0;
    left: 50%;
    transform-origin: 0 0;
    transition: transform 0.4s, opacity 0.2s;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08), 0 0 1px 0 rgba(0, 0, 0, 0.12);
    z-index: -1;
    transform: translate(-50%) scaleY(0);
}
.react-inputs-validation__select__options-container-animate___2Rm--.react-inputs-validation__show___2NI3u {
    transform: translate(-50%) scale(1);
    opacity: 1;
    z-index: 3;
}
.react-inputs-validation__select__options-container___d54qE {
    border: 1px solid #bbb;
    z-index: 1;
    margin-top: 2%;
    position: absolute;
    background: #fff;
    width: 100%;
    left: 50%;
    display: none;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08), 0 0 1px 0 rgba(0, 0, 0, 0.12);
    transform: translateX(-50%);
}
.react-inputs-validation__select__options-container___d54qE.react-inputs-validation__show___2NI3u {
    display: block;
    z-index: 1000;
}
.react-inputs-validation__select__dropdown___11yDr {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.react-inputs-validation__select__dropdown-icon___1T5r2 {
    width: 2%\9;
    display: inline-block\9;
    width: 20px;
    height: 20px;
}

.react-inputs-validation__select__dropdown-name___3hghL {
    width: 98%;
    display: inline-block;
   /*  color: #4F4F4F; */
    font-size: 15px;
    font-family: "Roboto";
    font-weight: 600;
}
.react-inputs-validation__select__dropdown-icon___1T5r2:before {
    content: "∨";
    position: absolute;
    font-size: 16px;
    font-weight: 600;
    /* top: 50%; */
    top:28px;
    transform: translateY(-50%);
    display: block;
    width: 0;
    border-color: var(--secondary-bg) transparent;
    border-style: solid;
    border-width: 0px;
}
.react-inputs-validation__select__dropdown-icon-container___2ild- {
    position: relative;
}
