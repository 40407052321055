.ql-editor{
    background:white;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 250px

}

.ql-container.ql-snow {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 1px solid #ccc;
    min-height: 250px;

}

.ql-toolbar.ql-snow {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background:white
}
